<template>
   <div style="width:100%">
		<v-card block :loading="isLoading">
			<v-card-title>
				Управление пользователями <v-chip label small class="ml-2">Всего: {{ total }}</v-chip>
				<v-spacer></v-spacer>
				
				<v-badge :overlap="(filterRoles.length > 0 ? true : false)" :content="filterRoles.length" class="mr-5" :color="(filterRoles.length > 0?'orange accent-4':'#fff')" :bordered="(filterRoles.length > 0 ? true : false)">
					<v-btn icon color="blue"  @click="dialogFilter = !dialogFilter">
						<v-icon>mdi-filter</v-icon>
					</v-btn>
				</v-badge>
				<v-btn color="primary" @click="getDialogNewUser()" elevation="0">Добавить пользователя</v-btn>
			</v-card-title>
			<v-card-text>
				<v-text-field label="Поиск пользователя" filled v-model="searchUser" clearable></v-text-field>
				<v-data-table :headers="headers" :items="users" :items-per-page="countRows" class="elevation-1" hide-default-footer>
					<template v-slot:item.email="{ item }">
						<a :href="`mailto:${item.email}`" class="link-email">{{ item.email }}</a> 
						
						<v-tooltip top color="grey">
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon small class="ml-2" v-bind="attrs" v-on="on">
									<v-icon color="grey lighten-2">mdi-clock-outline</v-icon>
								</v-btn>
							</template>
							<span>Регистрация: {{ item.created_at | moment("calendar") }}</span>
						</v-tooltip>
					</template>
					<template v-slot:item.count_roles="{ item }">
						<v-btn small elevation="0" @click="getRoles( item.id, item.email )">{{ item.count_roles }}</v-btn>
					</template>
					<template v-slot:item.first_name="{ item }">
						<span v-if="item.last_name" class="pr-1">{{ item.last_name }}</span><span v-else>-</span>
						<span v-if="item.first_name" class="pr-1">{{ item.first_name }}</span><span v-else>-</span>
						<span v-if="item.middle_name" class="pr-1">{{ item.middle_name }}</span>
					</template>
					<template v-slot:item.setting="{ item }">
						<v-btn small icon @click="openEditDialog( item.id )" :loading="loadingEditData==item.id" color="teal" class="mr-2">
							<v-icon>mdi-circle-edit-outline</v-icon>
						</v-btn>
						<v-btn small icon @click="deleteUser( item.id )" color="red">
							<v-icon>mdi-delete-outline</v-icon>
						</v-btn>
					</template>
				</v-data-table>
				<div class="pt-4">
					<v-row justify="space-between">
						<v-col cols=2 align-self="center">
							<v-select :items="itemsCountRows" v-model="countRows" label="Кол-во записей" hide-details solo></v-select>
						</v-col>
						<v-spacer></v-spacer>
						<v-col align-self="center" class="pag-end">
							<v-pagination v-model="page" :length="totalPage" :total-visible="7"></v-pagination>
						</v-col>
					</v-row>
				</div>
			</v-card-text>
		</v-card>

		<v-dialog v-model="dialogNewUser" persistent max-width="600px">
			<v-card>
			<v-card-title>
				<span class="text-h5">Добавить пользователя</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row dense>
						<v-col cols="12">
							<v-text-field label="Эл. почта*" required v-model="newUserForm.email"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field label="Пароль*" type="password" required v-model="newUserForm.password"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<v-text-field label="Фамилия*" required v-model="newUserForm.last_name"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<v-text-field label="Имя*" v-model="newUserForm.first_name"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<v-text-field label="Отчество*" required v-model="newUserForm.middle_name"></v-text-field>
						</v-col>
					</v-row>
				</v-container>
				<small>*поля обязательны для заполнения</small>
			</v-card-text>
			<v-card-actions>
				<v-btn color="red darken-1" text @click="dialogNewUser = false" >
					Закрыть
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="addNewUser()">
					Добавить
				</v-btn>
			</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogEditUser" max-width="600px">
			<v-card>
			<v-card-title>
				<span class="text-h5">Редактирования пользователя</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row dense>
						<v-col cols="12">
							<v-text-field label="Эл. почта*" required v-model="editUserForm.email"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<v-text-field label="Фамилия*" required v-model="editUserForm.last_name"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<v-text-field label="Имя*" v-model="editUserForm.first_name"></v-text-field>
						</v-col>
						<v-col cols="12" sm="6" md="4">
							<v-text-field label="Отчество*" required v-model="editUserForm.middle_name"></v-text-field>
						</v-col>
					</v-row>
				</v-container>
				<small>*поля обязательны для заполнения</small>
			</v-card-text>
			<v-card-actions>
				<v-btn color="red darken-1" text @click="dialogEditUser = false" >
					Закрыть
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="saveUserData()">
					Сохранить
				</v-btn>
			</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogFilter" max-width="600px">
			<v-card>
				<v-toolbar color="primary" dark>Фильтры</v-toolbar>
				<v-card-text>
					<h2 class="mt-3 mb-3">Фильтр ролей пользователей</h2>
					<div v-for="(item, k) in roles" :key="k" >
						<v-badge
							bordered
							color="#E0E0E0"
							icon="mdi-help"
							inline
							style="cursor: pointer"
							:title="item.desc"
						>
							<v-checkbox v-model="filterRoles" :value="item.id" :label="item.title" hide-details class="mt-0" />
						</v-badge>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary darken-1" text @click="dialogFilter = false" >
						Закрыть
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="teal darken-1" text @click="getUsers()">
						Применить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogRoles" max-width="700px">
			<v-card>
				<v-toolbar color="primary" dark>
					<v-toolbar-title>Список ролей пользователя {{emailSelectUserFromRole}} #{{idSelectUserFromRole}}</v-toolbar-title>
					<v-spacer></v-spacer>
					
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on" @click="dialogAddRole = !dialogAddRole">
								<v-icon>mdi-book-plus-multiple</v-icon>
							</v-btn>
						</template>
						<span>Добавить роль</span>
					</v-tooltip>

				</v-toolbar>
				<v-card-text>
					<div class="mt-2" v-if="userRoles.length > 0">
						<v-list-item two-line v-for="(item, k) in userRoles" :key="k">

							<v-list-item-avatar>
								<v-icon class="grey lighten-1" dark>
									mdi-select-group
								</v-icon>
							</v-list-item-avatar>

							<v-list-item-content>
								<v-list-item-title>{{ item.title }}</v-list-item-title>
								<!-- <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle> -->
								<v-list-item-subtitle>
									{{ item.assignable_type_desc }} #{{ item.assignable_id }}
								</v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-action>
								<div>
									<v-btn small icon @click="openEditRole( item.id )" :loading="loadingEditBtnRole==item.id" color="teal" class="mr-2">
										<v-icon>mdi-circle-edit-outline</v-icon>
									</v-btn>
									<v-btn icon @click="deleteRole( item.id )" color="red">
										<v-icon>mdi-delete-outline</v-icon>
									</v-btn>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn color="#E0E0E0" v-bind="attrs" v-on="on" icon>
												<v-icon dark>mdi-help</v-icon>
											</v-btn>
										</template>
										<span>{{item.desc}}</span>
									</v-tooltip>
								</div>
							</v-list-item-action>

						</v-list-item>
					</div>

					<v-alert v-else border="top" colored-border type="info" elevation="2" class="mt-4" >
						У пользователя отсутствуют доступные роли
					</v-alert>

				</v-card-text>
				<v-card-actions>
					<v-btn color="primary darken-1" text @click="dialogRoles = false" >
						Закрыть
					</v-btn>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogAddRole" max-width="600px">
			<v-card>
				<v-toolbar color="primary" dark>
					<v-toolbar-title>Добавить роль пользователю #{{idSelectUserFromRole}}</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<div class="mt-5">
						<v-select
							:items="roles"
							v-model="newUserRole.role_id"
							@change="getRoleCategory(newUserRole.role_id)"
							outlined
							item-text="title"
							item-value="id"
							label="Выберите роль"
						></v-select>
						<v-select
							v-if="newUserRole.role_id"
							:items="rolesAssignableTypes"
							item-text="title"
							item-value="assignable_type"
							@change="getAssignableIds(newUserRole.assignable_type)"
							v-model="newUserRole.assignable_type"
							outlined
							label="Выберите категорию"
						></v-select>
						<!-- <v-select
							v-if="newUserRole.assignable_type"
							:items="rolesAssignableId"
							v-model="newUserRole.assignable_id"
							item-text="name"
							item-value="id"
							outlined
							label="Выберите организацию"
						></v-select> -->
						<v-autocomplete
							v-if="newUserRole.assignable_type"
							:items="rolesAssignableId"
							v-model="newUserRole.assignable_id"
							item-text="name"
							item-value="id"
							outlined
							label="Выберите организацию"
						></v-autocomplete>
						<v-switch
							v-model="newUserRole.state"
							label="Роль активна"
						></v-switch>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary darken-1" text @click="dialogAddRole = false" >
						Закрыть
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="teal darken-1" text @click="addNewRole()" :loading="btnAddRole" :disabled="!(newUserRole.role_id > 0)">
						Добавить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogEditRole" max-width="600px">
			<v-card>
				<v-toolbar color="primary" dark>
					<v-toolbar-title>Редактирование роли #{{ editRoleForm.id }} пользователя #{{idSelectUserFromRole}}</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<div class="mt-5">
						<v-select
							:items="roles"
							v-model="editRoleForm.role_id"
							@change="getRoleCategory(editRoleForm.role_id)"
							outlined
							item-text="name"
							item-value="id"
							label="Выберите роль"
						></v-select>
						<v-select
							v-if="editRoleForm.role_id"
							:items="rolesAssignableTypes"
							@change="getAssignableIds(editRoleForm.assignable_type)"
							v-model="editRoleForm.assignable_type"
							outlined
							label="Выберите категорию"
						></v-select>
						<v-select
							v-if="editRoleForm.assignable_type"
							:items="rolesAssignableId"
							v-model="editRoleForm.assignable_id"
							item-text="name"
							item-value="id"
							outlined
							label="Выберите организацию"
						></v-select>
						<v-switch
							v-model="editRoleForm.state"
							label="Роль активна"
						></v-switch>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary darken-1" text @click="dialogEditRole = false" >
						Закрыть
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="teal darken-1" text @click="saveEditRole()" :loading="btnEditRole" :disabled="!(editRoleForm.role_id > 0)">
						Сохранить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
   </div>
</template>

<script>
const defaultFormNewRole = Object.freeze({
	state : true
})

export default {
	data () {
		return {
			isLoading : true,

			headers: [
				{ text: 'ID', align: 'start', sortable: true, value: 'id' },
				{ text: 'Email', value: 'email' },
				{ text: 'ФИО', value: 'first_name' },
				{ text: 'Ролей', value: 'count_roles', align: 'center', sortable: false },
				// { text: 'Регион', value: 'region_name', align: 'left', sortable: true },
				{ text: 'Управление', value: 'setting', align: 'right', sortable: false },
			],

			users: [],
			roles: [],

			total: 0,
			page: 1,
			totalPage: 1,
			countRows: 12,

			itemsCountRows : [
				12, 20, 50, 100, 500
			],

			dialogNewUser: false,
			newUserForm: {},

			dialogEditUser: false,
			editUserForm: {},
			loadingEditData: 0,

			searchUser: '',

			dialogFilter: false,
			filterRoles: [],
			filtersInfoHover: false,

			dialogRoles: false,
			idSelectUserFromRole: 0,
			emailSelectUserFromRole: '',
			userRoles: [],

			dialogAddRole: false,
			rolesAssignableTypes: [],
			rolesAssignableId: [],
			newUserRole: Object.assign({}, defaultFormNewRole),
			btnAddRole: false,

			dialogEditRole: false,
			loadingEditBtnRole: 0,
			editRoleForm: {},
			btnEditRole: false,
		}
	},
	watch: {
		'page': function(){
			if ( !this.isLoading )
				this.getUsers()
		},
		'countRows': function(val) {
			if ( !this.isLoading ) {
				this.getUsers()
				localStorage.setItem("countRows", parseInt(val))
			}
		},
		'searchUser': function() {
			this.getUsers()
		}
	},
	methods: {
		// ? Сохранить изменения по пользователю 
		saveUserData() {
			this.$confirm.q(`Вы подтверждаете изменение данных пользователя #${this.editUserForm.id}?`, () => {
				this.$http.post(`users/user/${this.editUserForm.id}`,{
					form: this.editUserForm
				}).then((response) => {
					if ( response.data.status ) {
						this.$toast.open('Пользователь отредактирован');

						this.dialogEditUser = false
						// ? Обновляем информацию в списке пользователей 
						setTimeout(() => {
							this.getUsers()
						},100)
					} else {
						this.$toast.error("Возникли ошибки при редактировании пользователя");
					}
				});
			}, () => {
				this.$toast.warning("Действие отменено пользователем");
			})
		},
		// ? Сохранить отредактированную роль 
		saveEditRole() {
			this.$confirm.q(`Вы подтверждаете изменение данных роли #${this.editRoleForm.id}?`, () => {
				this.$http.post(`users/role/${this.editRoleForm.id}`,{
					form: this.editRoleForm
				}).then((response) => {
					if ( response.data.status ) {
						this.$toast.open('Роль отредактирована');
						this.getRoles(this.idSelectUserFromRole)

						this.dialogEditRole = false
						// ? Обновляем информацию в списке пользователей 
						setTimeout(() => {
							this.getUsers()
						},1000)
					} else {
						this.$toast.error("Возникли ошибки при редактировании роли");
					}
				});
			}, () => {
				this.$toast.warning("Действие отменено пользователем");
			})
		},
		// ? Редактировать роль 
		openEditRole( id ) {
			this.loadingEditBtnRole = id
			this.$http.get(`users/role/${id}`).then((response) => {
				if ( response.data.status ) {
					this.editRoleForm = response.data.role
					this.dialogEditRole = true
					
					this.editRoleForm.assignable_id = parseInt(this.editRoleForm.assignable_id)

					// ? Получаем сразу все категории роли
					this.getRoleCategory(this.editRoleForm.role_id)

					// ? Получаем организации роли 
					this.getAssignableIds(this.editRoleForm.assignable_type)

					setTimeout(() => {
						this.loadingEditBtnRole = 0
					},600)
				} 
			});
		},
		// ? Удалить роль пользователя 
		deleteRole( id ) {
			this.$confirm.q(`Вы подтверждаете удаление роли #${id}?`, () => {
				this.$http.delete(`users/role/${id}`).then((response) => {
					if ( response.data.status ) {
						this.$toast.open('Роль удалена');
						this.getRoles(this.idSelectUserFromRole)

						// ? Обновляем информацию в списке пользователей 
						setTimeout(() => {
							this.getUsers()
						},1000)
					} else {
						this.$toast.error("Возникли ошибки при удалении роли");
					}
				});
			}, () => {
				this.$toast.warning("Действие отменено пользователем");
			})
		},
		// ? Добавить роль пользователю 
		addNewRole(){
			this.btnAddRole = true
			this.$http.put(`users/${this.idSelectUserFromRole}/role`, {
				form : this.newUserRole
			}).then((response) => {
				if ( response.data.status ) {
					this.btnAddRole = false
					this.getRoles(this.idSelectUserFromRole)
					this.newUserRole = Object.assign({}, defaultFormNewRole)
					this.$toast.open('Роль успешно добавлена');
					this.dialogAddRole = false

					// ? Обновляем информацию в списке пользователей 
					setTimeout(() => {
						this.getUsers()
					},1000)
				} else {
					if ( response.data.reason ) {
						this.$toast.error(response.data.reason);
					} else {
						this.$toast.error("Что-то пошло не так");
					}
				}
			});
		},
		// ? Получить организации для роли
		getAssignableIds(assignable_type){
			this.$http.get(`users/role/${assignable_type}/assignable_id`).then((response) => {
				if ( response.data.status ) {
					this.rolesAssignableId = response.data.assignable_ids
				} 
			});
		},	
		// ? Получить категории, которые есть у даной роли 
		getRoleCategory(role_id){
			this.$http.get(`users/role/${role_id}/assignable_types`).then((response) => {
				if ( response.data.status ) {
					this.rolesAssignableTypes = response.data.assignable_types
				} 
			});
		},	
		// ? Получить список ролей 
		getRoles(user_id, email) {
			this.userRoles = []
			this.idSelectUserFromRole = user_id
			this.emailSelectUserFromRole = email
			this.dialogRoles = true

			this.$http.get(`users/user/${user_id}/roles`).then((response) => {
				if ( response.data.status ) {
					this.userRoles = response.data.roles
				} 
			});
		},
		openEditDialog(user_id) {
			this.loadingEditData = user_id
			this.$http.get(`users/user/${user_id}`).then((response) => {
				if ( response.data.status ) {
					this.editUserForm = response.data.user
					this.dialogEditUser = true

					setTimeout(() => {
						this.loadingEditData = 0
					},600)
				} 
			});
		},
		deleteUser(id) {
			this.$confirm.q(`Вы подтверждаете удаление пользователя #${id}?`, () => {
				this.$http.delete(`users/user/${id}`).then((response) => {
					if ( response.data.status ) {
						this.$toast.open('Пользователь удален');
						this.getUsers()
					} else {
						this.$toast.error("Возникли ошибки при удалении пользователя");
					}
				});
			}, () => {
				this.$toast.warning("Действие отменено пользователем");
			})
		},
		addNewUser(){
			this.$http.post("users/registration",this.newUserForm).then((response) => {
				if ( response.data.status ) {
					this.dialogNewUser = false
					this.newUserForm = {}
					this.getUsers()
					this.$toast.open('Пользователь успешно добавлен');
				} else {
					if ( response.data.reason ) {
						this.$toast.error(response.data.reason);
					} else {
						this.$toast.error("Что-то пошло не так");
					}
				}
			}).catch(() => {
				this.$toast.error("Что-то пошло не так");
			});
		},
		getDialogNewUser(){
			this.dialogNewUser = true
		},
		getUsers(){
			this.isLoading = true
			this.$http.get("users", { params: {
				count: this.countRows,
				page: this.page,
				search: this.searchUser,
				roles: this.filterRoles.join("_"),
				atype: this.$route.query.atype,
				aid: this.$route.query.aid
			} }).then((response) => {
				this.users = response.data.items
				this.roles = response.data.roles
				this.total = parseInt(response.data.options.total)
				this.page = parseInt(response.data.options.page)
				this.totalPage = parseInt(response.data.options.totalPage)
				this.dialogFilter = false

				setTimeout(() => {
					this.isLoading = false
				}, 200)
			}).catch(() => {
				this.$toast.error("Что-то пошло не так - сервер не ответил");
			});
		}
	},
	created(){
		const countRows = localStorage.getItem("countRows")
		if ( countRows && parseInt(countRows) > 0 ) {
			this.countRows = parseInt(countRows)
		}
		this.getUsers()
	}
}
</script>

<style>
.link-email { color:#1A237E; text-decoration: none !important; border-bottom: 1px dashed #1A237E;}
.pag-end .v-pagination { justify-content: right !important; }
</style>